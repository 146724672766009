@import '../../../../variables.scss';

.SalesItemDrawer {
    left: unset;
}

.SalesItemDrawer__Drawer {
    width: 540px;

    .MyDrawer__Drawer__Body {
        padding-top: 0;
    }
}

.SalesItemDrawer__Header__BackButton {
    padding: 4px;
    height: auto;
    min-width: 0;

    .MyButton__Inner__IconRight {
        font-size: 28px;
    }
}

.SalesItemDrawer__PageHeader {
    .PageHeader__TitleContainer__Title {
        font-size: 20px;
    }
}

.SalesItemDrawer__CancelledMessage {
    margin-top: 16px;
    margin-bottom: 8px;
}

.SalesItemDrawer__DetailsTab {
    padding: 8px 8px 60px 8px;
    // padding-bottom: 60px;
}

.SalesItemDrawer__SectionHeader {
    margin-top: 24px;
    font-size: 16px;
}

.SalesItemDrawer__DetailsTab__MeasurementsLoading {
    height: 58px;
}

.SalesItemDrawer__SectionEmpty {
    color: $color-grey-light;
}

.SalesItemDrawer__PricingDisplay {
    font-size: 16px;
    border-spacing: 0;
    margin-top: 24px;
    width: 100%;

    th,
    td {
        font-weight: $font-weight-normal;
        text-align: left;
        padding-bottom: 8px;
        padding-right: 32px;
    }
    th {
        color: $color-grey-base;
    }

    thead {
        th:first-child {
            font-weight: $font-weight-bold;
            color: black;
            padding-bottom: 16px;
        }
    }
    tbody {
        // th {
        //     padding-right: 24px;
        // }
        td {
            font-weight: $font-weight-normal;
            text-align: left;
        }
    }
}

.SalesItemDrawer__AdvancedOptionsLink {
    margin-top: 12px;
}

.SalesItemDrawer__AdvancedOptionsModal__Frame {
    width: 700px;
}

.SalesItemDrawer__HistoryTable {
    .DataTable__DataCell:first-child {
        padding-left: 12px;
    }
    .DataTable__DataCell:last-child {
        padding-right: 12px;
    }
}

// Work Items
.SalesItemDrawer__WorkItemsTab {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.SalesItemDrawer__WorkItemsTab__Loading {
    display: flex;
    justify-content: center;
    padding: 24px 0;
}

.SalesItemDrawer__WorkItemsTab__SectionEmpty {
    text-align: center;
    color: $color-grey-light;
    padding: 12px;
}

.SalesItemDrawer__WorkItemBlock__Header {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 12px 0;
}

.SalesItemDrawer__WorkItemBlock__Header__Title {
    display: flex;
    gap: 6px;
    align-items: center;
    font-weight: $font-weight-normal;
    position: relative;

    .tuid {
        margin: 0;
        color: $color-grey-light;
        font-weight: $font-weight-normal;
    }
}

.SalesItemDrawer__WorkItemBlock__Header__Date {
    .Link {
        display: flex;
        align-items: center;
        gap: 6px;
        // justify-self: flex-end;
    }
}

.SalesItemDrawer__WorkItemBlock__Header__HistoryButton {
    position: absolute;
    top: 0;
    right: 0;
}
